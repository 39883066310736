import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from "Assets/Logo/tinklo-black.svg";
import linkedIn from "Assets/Icon/linkedin.svg";
import Grid from "@mui/material/Grid";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <Box>
      <Container maxWidth="xl">
        <Grid container columns={14} padding="36px 0">
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <img src={logo} alt="tinklo" width={125} />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography
              mt={{ xs: 2, md: 0 }}
                sx={{ fontSize: 14, fontFamily: "Bold", "&:hover": {
                  cursor: "pointer",
                }}}
                onClick={() =>
                  navigate("/", { state: { targetId: "services" } })
                }
              >
                Nos services
              </Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography
                sx={{ fontSize: 14, fontFamily: "Bold", "&:hover": {
                  cursor: "pointer",
                }}}
                onClick={() =>
                  navigate("/", { state: { targetId: "expertises" } })
                }
              >
                Nos expertises
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography sx={{ fontSize: 14, fontFamily: "Bold", "&:hover": {
                  cursor: "pointer",
                }}}
              onClick={() =>
                navigate("/", { state: { targetId: "team" } })
              }>
                Notre équipe
              </Typography>
              
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography
                sx={{ fontSize: 14, fontFamily: "Bold", "&:hover": {
                  cursor: "pointer",
                }}}
                onClick={() =>
                  navigate("/", { state: { targetId: "achievements" } })
                }
              >
                Nos réalisations
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography
              mb={{ xs: 2, md: 0 }}
              sx={{ fontSize: 14, fontFamily: "Bold", "&:hover": {
                  cursor: "pointer",
                }}} onClick={() => window.location.href = 'mailto:contact@tinklo.fr'}>
                Nous contacter
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
              sx={{ "&:hover": {
                cursor: "pointer",
              }}}
            >
              <img src={linkedIn} alt="tinklo" width={30} onClick={() => window.open('https://www.linkedin.com/company/tinklo/','_blank')} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box bgcolor="#F6F5F4">
        <Container maxWidth="xl">
          <Box display="flex" justifyContent="space-between" padding="32px">
            <Typography sx={{ fontSize: 13 }}>@ 2024 Tinklo</Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
