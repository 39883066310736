import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const theme = createTheme({
  typography: {
    fontFamily: 'Regular, Roboto',
  },
  palette: {
    primary: {
      light: "#464b57",
      main: "#181E2E",
      dark: "#101520",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f9cf35",
      main: "#F8C303",
      dark: "#ad8802",
      contrastText: "#000",
    },
  },
});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
