import { TypeAnimation } from "react-type-animation";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export default function MainBanner() {
  return (
      <Box sx={{ background: "#181E2E" }}>
        <Container maxWidth="lg">
          <Box
            color="#FFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            py={{ xs: 5, md: 10 }}
          >
            <Box fontFamily="Bold" fontSize={{ xs: 20, sm: 32, md: 50 }}>
              <mark
                className="font-bold"
                style={{
                  padding: "0px 12px",
                  backgroundColor: "#F8C303",
                  borderRadius: "8px",
                  color: 'white',
                  fontFamily: "Bold"
                }}
              >
                Donnez vie
              </mark>{" "}
              à vos projets
            </Box>
            <Box fontFamily="Bold" fontSize={{ xs: 20, sm: 30, md: 50 }}>
              numériques les plus{" "}
              <TypeAnimation
                sequence={[
                  "ambitieux",
                  2000,
                  "audacieux",
                  2000,
                  "complexes",
                  2000,
                  "innovants",
                  2000,
                  "exigeants",
                  2000,
                ]}
                speed={10}
                repeat={Infinity}
                style={{ color: "white", fontFamily: "Bold" }}
              />
            </Box>
            <Box mt={{ xs: 2, sm: 3, md: 5 }} fontSize={{ xs: 16, sm: 16, md: 20 }} textAlign='center'>
              De l'idée à la réalisation, nous vous accompagnons dans la
              création
            </Box>
            <Box  fontSize={{ xs: 16, sm: 16, md: 20 }} textAlign='center'>
              de solutions numériques performantes et innovantes.
            </Box>
          </Box>
        </Container>
      </Box>
  );
}
