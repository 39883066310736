import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import SimpleButton, { ButtonStyle } from "Components/Button";
import Typography from "@mui/material/Typography";

export default function BottomBanner() {
  return (
    <Box>
      <Container maxWidth="xl">
        <Box
          display="flex"
					flexDirection='column'
          justifyContent="center"
          textAlign="center"
					paddingY='36px'
          mt={{ xs: 3, sm: 5, md: 6 }}
					bgcolor='#181E2E'
					borderRadius='8px'
					gap={4}
        >
          <Typography
            sx={{
              fontSize: { xs: 22, sm: 32, md: 40 },
              fontFamily: "SemiBold",
              lineHeight: "1",
							color: 'white'
            }}
          >
            Prêt à construire votre produit numérique ?  
          </Typography>
					<Typography
            sx={{
              fontSize: 16,
							color: 'white'
            }}
          >
            Parlons de votre projet.
          </Typography>
					<Box>
					<SimpleButton text="Nous contacter" style={ButtonStyle.primary} onClick={() => window.location.href = 'mailto:contact@tinklo.fr'}/>
					</Box>
					
        </Box>
        
      </Container>
    </Box>
  );
}
