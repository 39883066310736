import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import card1 from "Assets/Icon/card1.svg";
import card2 from "Assets/Icon/card2.svg";
import card3 from "Assets/Icon/card3.svg";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import VizSensor from "react-visibility-sensor";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const cards2 = [
  {
    icon: card1,
    time: 600,
    text1: "Produit",
    text2: "d’hébergement",
    text3: "Cloud",
    text4:
      "Grâce à notre approche multidisciplinaire combinant des compétences en ingénierie, en business et des méthodologies éprouvées, nous sommes en mesure d'identifier précisément vos exigences et de concevoir des solutions adaptées à vos besoins spécifiques.",
  },
  {
    icon: card2,
    time: 900,
    text1: "Tech",
    text2: "intégrées et",
    text3: "prêtes à l’emploi",
    text4:
      "Nous excellons dans les technologies, avec une expertise solide en développement logiciel, architecture système et cybersécurité. Toujours à la pointe des dernières avancées, nous proposons à nos clients des solutions innovantes et performantes.",
  },
  {
    icon: card3,
    time: 1200,
    text1: "IA & RO",
    text2: "numériques",
    text3: "sur-mesure",
    text4:
      "Nous intégrons l'intelligence artificielle et la recherche opérationnelle dans les processus décisionnels de nos clients pour optimiser leur efficacité opérationnelle et leurs prises de décision grâce à des techniques avancées d'analyse, de modélisation et d'optimisation.",
  },
];

const styles = {
  root: {
    padding: "0 30px",
  },
  slideContainer: {
    padding: "0 10px",
  },
};

export default function ExpertisesSection() {
  const [active, setActive] = React.useState(false);
  const containerRef = React.useRef<HTMLElement>(null);

  return (
    <Box id="expertises">
      <Container maxWidth="lg" sx={{ padding: "0" }}>
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          padding="0 12px"
          mt={{ xs: 3, sm: 5, md: 9 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 22, sm: 32, md: 40 },
              fontFamily: "SemiBold",
              lineHeight: "1",
            }}
            gutterBottom
          >
            Partenaires de votre réussite,
            <br />
            nos expertises sont à votre service
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={{ xs: 1, sm: 2, md: 3 }}
          bgcolor="#F6F5F4"
          borderRadius="8px"
        >
          <Box padding="22px 28px">
            <ManageAccountsIcon sx={{ color: "#F8C303" }} />
            <Typography
              sx={{ fontSize: 14, lineHeight: "1" }}
              gutterBottom
              mt={1}
            >
              Comprendre vos besoins, concevoir et réaliser des solutions
              numériques adaptées à vos enjeux,
              <br />
              vous accompagner dans votre transformation numérique : c'est notre
              mission.
            </Typography>
            
          </Box>

          <VizSensor
            partialVisibility
            onChange={(isVisible: any) => {
              if (!active) {
                setActive(isVisible);
              }
            }}
          >
            <Box
              display={{ xs: "none", md: "flex" }}
              gap={2}
              justifyContent="center"
              padding="0 22px 28px 22px"
              ref={containerRef}
            >
              {cards2.map((card) => (
                <Slide
                  direction="up"
                  key={card.text1}
                  in={active}
                  timeout={card.time}
                  container={containerRef.current}
                >
                  <div>
                    <Fade in={active} timeout={card.time} key={card.text1}>
                      <Card
                        sx={{
                          flexGrow: 1,
                          borderRadius: "8px",
                          width: "100%",
                          paddingTop: "16px",
                        }}
                        elevation={0}
                      >
                        <CardContent>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            gap={3}
                          >
                            <img src={card.icon} alt="tinklo" width={45} />
                            <Typography
                              sx={{
                                fontSize: 32,
                                fontFamily: "Bold",
                                lineHeight: "1",
                              }}
                              gutterBottom
                            >
                              {card.text1}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 14,
                                lineHeight: "1.2",
                                textAlign: "center",
                              }}
                              gutterBottom
                            >
                              {card.text4}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Fade>
                  </div>
                </Slide>
              ))}
            </Box>
          </VizSensor>

          <Box display={{ xs: "block", md: "none" }} mb={3}>
            <AutoPlaySwipeableViews
              enableMouseEvents
              slideStyle={styles.slideContainer}
              style={styles.root}
            >
              {cards2.map((card) => (
                <Card
                  sx={{
                    flexGrow: 1,
                    borderRadius: "8px",
                    width: "100%",
                    paddingTop: "16px",
                  }}
                  elevation={0}
                  key={card.text1}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={3}
                    >
                      <img src={card.icon} alt="tinklo" width={45} />
                      <Typography
                        sx={{
                          fontSize: 32,
                          fontFamily: "Bold",
                          lineHeight: "1",
                        }}
                        gutterBottom
                      >
                        {card.text1}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          lineHeight: "1.2",
                          textAlign: "center",
                        }}
                        gutterBottom
                      >
                        {card.text4}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </AutoPlaySwipeableViews>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
