import * as React from "react";
import MainBanner from "./Sections/MainBanner";
import ServicesCards from "Components/ServicesCards";
import { useLocation } from "react-router-dom";
import ExpertisesSection from "./Sections/ExpertisesSection";
import AchievementsSection from "./Sections/AchievementsSection";
import "./style.scss";
import OurTeam from "./Sections/OurTeam";
import BottomBanner from "./Sections/BottomBanner";

function Home() {
  const { state } = useLocation();
  const { targetId } = state || {};

  React.useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [targetId]);

  return (
    <>
      <MainBanner />
      <ServicesCards />
      <ExpertisesSection />
      <OurTeam />
      <AchievementsSection />
      <BottomBanner />
    </>
  );
}

export default Home;
