import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SimpleButton, { ButtonStyle } from "../Button";
import logo from "Assets/Logo/tinklo-white.svg";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import Drawer from "@mui/material/Drawer";

const pages = [
  { id: 0, title: "Nos services", link: "services" },
  { id: 1, title: "Nos expertises", link: "expertises" },
  { id: 3, title: "Notre équipe", link: "team" },
  { id: 2, title: "Nos réalisations", link: "achievements" },
];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    console.log('open')
    setOpen(newOpen);
  };

  const handleMenuClick = (id: string) => {
    setOpen(false);
    navigate("/", { state: { targetId: id }})
  }
  const DrawerList = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={3}
      my={3}
    >
      {pages.map((page) => (
        <Link
          key={page.id}
          component="button"
          variant="body2"
          onClick={() => handleMenuClick(page.link)}
        >
          <Typography
            sx={{
              fontSize: 14,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {page.title}
          </Typography>
        </Link>
      ))}
      <Box flexGrow={0} gap={3} sx={{ display: "flex", mr: 1 }}>
        <SimpleButton text="Nous contacter" style={ButtonStyle.primary} onClick={() => window.location.href = 'mailto:contact@tinklo.fr'} />
      </Box>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ boxShadow: "none" }}>
      <Container maxWidth="xl" sx={{ padding: 2 }}>
        <Drawer open={open} onClose={toggleDrawer(false)} anchor={"top"}>
          {DrawerList}
        </Drawer>
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="tinklo" width={125} />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              mr: 1,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="tinklo" width={85} />
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
            gap={3}
          >
            {pages.map((page) => (
              <Link
                key={page.id}
                component="button"
                variant="body2"
                onClick={() =>
                  navigate("/", { state: { targetId: page.link } })
                }
              >
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: 14,
                    "&:hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                >
                  {page.title}
                </Typography>
              </Link>
            ))}
          </Box>
          <Box
            flexGrow={0}
            gap={3}
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          >
            <SimpleButton text="Nous contacter" style={ButtonStyle.primary} onClick={() => window.location.href = 'mailto:contact@tinklo.fr'} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
