import { ReactElement } from 'react'
import Button from '@mui/material/Button'

import './style.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  error = 'error',
	white = 'white',
  outline = 'outline'
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
}

export default function SimpleButton({
  text, onClick, style, disabled,
}: Props): ReactElement {
  return (
    <Button
      className={`simple-button button--${style}`}
      onClick={onClick}
      disabled={disabled}
      sx={{ textTransform: 'none', fontFamily: 'Medium'}}
    >
      {text}
    </Button>
  )
}

const defaultProps = {
  style: ButtonStyle.primary,
  disabled: false,
  onClick: () => { /* to implement */ },
}

SimpleButton.defaultProps = defaultProps
