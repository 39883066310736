import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import mapIcon from "Assets/Icon/map.svg";
import couldIcon from "Assets/Icon/cloud.svg";
import crmIcon from "Assets/Icon/crm.svg";
import optimisationIcon from "Assets/Icon/optimisation.svg";
import img from "Assets/Img/img.png";

const cards2 = [
  {
    icon: couldIcon,
    text1: "Migration vers le cloud",
    text2:
      "Migration complète du SI d'une entreprise de services vers une infrastructure cloud sécurisée et évolutive",
  },
  {
    icon: optimisationIcon,
    text1: "Optimisation des ressources",
    text2:
      "Conception et déploiement de solutions d'optimisation pour les principales ressources d'une grande entreprise industrielle",
  },
  {
    icon: crmIcon,
    text1: "Intégration d’une solution CRM",
    text2:
      "Mise en place d'un système de gestion de la relation client (CRM) pour une entreprise de services",
  },
];

export default function AchievementsSection() {
  return (
    <Box id='achievements'>
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          mt={{ xs: 3, sm: 5, md: 9 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 22, sm: 32, md: 40 },
              fontFamily: "SemiBold",
              lineHeight: "1",
            }}
            gutterBottom
          >
            Nos réalisations :
            <br />
            des exemples concrets de notre savoir-faire
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" textAlign="center" mb={4}>
          <Container maxWidth="md">
            <Typography sx={{ fontSize: 14, lineHeight: "1" }} gutterBottom>
              Nous sommes fiers de notre expérience et de nos réalisations.
              Depuis plus de trois ans, nous accompagnons de nombreuses
              entreprises de toutes tailles et de tous secteurs d'activité dans
              leur transformation numérique.
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 4 }}>
          <Grid item xs={12} md={8}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              bgcolor="#F6F5F4"
              borderRadius="8px"
            >
              <Box
                padding="22px 28px"
                gap={2}
                display="flex"
                flexDirection="column"
              >
                <img src={mapIcon} alt="tinklo" width={24} />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "1",
                    fontFamily: "SemiBold",
                  }}
                >
                  Production cartographique à grande échelle : Booster votre
                  efficacité avec un système d’information géographique
                </Typography>
                <Typography sx={{ fontSize: 14, lineHeight: "1" }} gutterBottom>
                  Production cartographique à grande échelle : Booster votre
                  efficacité avec un système d’information géographique
                </Typography>
              </Box>

              <img
                src={img}
                alt="tinklo"
                width="80%"
                style={{ marginTop: "auto", marginLeft: "auto" }}
              />
            </Box>
          </Grid>

          <Grid item md={4}>
            <Box
              display="flex"
              flexDirection="column"
              gap={{ xs: 1, sm: 2, md: 4 }}
            >
              {cards2.map((card) => (
                <Card
                  sx={{
                    borderRadius: "8px",
                    background: "#F6F5F4",
                  }}
                  key={card.text1}
                  elevation={0}
                >
                  <CardContent sx={{ padding: "22px 28px" }}>
                    <Box display="flex" flexDirection="column" gap={3}>
                      <img src={card.icon} alt="tinklo" width={24} />
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontFamily: "SemiBold",
                          lineHeight: "1",
                        }}
                      >
                        {card.text1}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          lineHeight: "1.2",
                        }}
                      >
                        {card.text2}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
