import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ResponsiveAppBar from "Components/Header/Header";
import "./App.scss";
import Home from "Pages/Home/Home";
import Footer from "Components/Footer/Footer";
import ScrollToTop from "Components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <ResponsiveAppBar />
      <div id="app">
        <Routes>
          <Route element={<Home />} path="/" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
