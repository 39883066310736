import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export default function OurTeam() {
  return (
    <Box id="team">
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          mt={{ xs: 3, sm: 5, md: 9 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 22, sm: 32, md: 40 },
              fontFamily: "SemiBold",
              lineHeight: "1",
            }}
            gutterBottom
          >
            Notre équipe :
            <br />
            des experts passionnés à votre service
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" textAlign="center" mb={4}>
          <Container maxWidth="md">
            <Typography sx={{ fontSize: 14, lineHeight: "1" }} gutterBottom>
              Nous sommes convaincus que le succès de votre transformation
              numérique dépend de la qualité de notre équipe.
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} md={4}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              bgcolor="#F6F5F4"
              borderRadius="8px"
              padding="22px 28px"
              gap={{ xs: 1, md: 2 }}
              textAlign="center"
              justifyContent="center"
            >
              <Typography sx={{ fontSize: 16, fontFamily: "SemiBold" }}>
                Une histoire commune
              </Typography>
              <Typography
                sx={{ fontSize: 64, fontFamily: "SemiBold", color: "#F9C203" }}
              >
                +5
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                années de collaboration
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              bgcolor="#F6F5F4"
              borderRadius="8px"
              padding="22px 28px"
              gap={{ xs: 1, md: 1 }}
              textAlign="center"
            >
              <Typography sx={{ fontSize: 16, fontFamily: "SemiBold" }}>
                Des valeurs qui nous rassemblent
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                Tous les membres de notre équipe partagent les mêmes valeurs
              </Typography>
              <Box gap={0.5} display="flex" justifyContent='flex-end'  padding={1} borderRadius={1}
              flexDirection="column">
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "SemiBold",
                  color: "#138776",
                  backgroundColor: "#E2F2F1",
                  borderRadius: '4px'
                }}
              >
                Collaboration
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "SemiBold",
                  color: "#EA4F44",
                  backgroundColor: "#FCE9E8",
                  borderRadius: '4px'
                }}
              >
                Excellence
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "SemiBold",
                  color: "#0984CF",
                  backgroundColor: "#E3ECF3",
                  borderRadius: '4px'
                }}
              >
                Innovation 
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "SemiBold",
                  color: "#F9C203",
                  backgroundColor: "#FFF6D6",
                  borderRadius: '4px'
                }}
              >
                Engagement
              </Typography>
              </Box>
              
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              bgcolor="#F6F5F4"
              borderRadius="8px"
              padding="22px 28px"
              gap={{ xs: 1, md: 2 }}
              textAlign="center"
              justifyContent="center"
            >
              <Typography sx={{ fontSize: 16, fontFamily: "SemiBold" }}>
                La force d’une communauté
              </Typography>
              <Typography
                sx={{ fontSize: 64, fontFamily: "SemiBold", color: "#F9C203" }}
              >
                +20
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                experts à votre service
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
