import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import logoRed from "Assets/Logo/icon-red.svg";
import logoYellow from "Assets/Logo/icon-yellow.svg";
import logoBlue from "Assets/Logo/icon-blue.svg";
import logoGreen from "Assets/Logo/icon-green.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const cards = [
  {
    icon: logoRed,
    path: '/services-cloud',
    text1: "services",
    text2: "d’hébergement",
    text3: "Cloud",
    text4:
      "Assurez la haute disponibilité, la sécurité et l’évolution de vos infrastructures informatiques.",
  },
  {
    icon: logoBlue,
    path: '/solutions-integrees',
    text1: "solutions",
    text2: "intégrées et",
    text3: "prêtes à l’emploi",
    text4:
      "Intégration fluide de solutions SaaS, pour une mise en œuvre rapide et sécurisée.",
  },
  {
    icon: logoGreen,
    path: '/sur-mesure',
    text1: "produits",
    text2: "numériques",
    text3: "sur-mesure",
    text4:
      "Nous réalisons avec vous un produit sur-mesure, de bout en bout, répondant aux plus hauts standards techniques. ",
  },
  {
    icon: logoYellow,
    path: '/innovations',
    text1: "innovations",
    text2: "axées sur les",
    text3: "besoins",
    text4:
      "Améliorez l'efficacité de vos processus décisionnels en intégrant nos solutions en intelligence artificielle et recherche opérationnelle.",
  },
];

const styles = {
  root: {
    padding: '0 30px',
  },
  slideContainer: {
    padding: '0 10px',
  },
};

export default function ServicesCards() {
  return (
    <Box id='services'>
      <Container maxWidth="lg" sx={{ padding: '0 !important'}}>
        <Box display={{ xs: 'none', md: 'flex' }} gap={4} mt={3} justifyContent="center">
          {cards.map((card) => (
            <Card
              sx={{
                minWidth: "195px",
                maxWidth: "195px",
                background: "#181E2E",
                borderRadius: "8px",
                border: "solid #181E2E 2px"
              }}
              elevation={0}
              key={card.text1}
              className="card"
            >
              <CardContent>
                <img src={card.icon} alt="tinklo" width={46} />
                <Typography
                  sx={{ fontSize: 20, fontFamily: "Bold", lineHeight: "1" }}
                  mt={1}
                >
                  {card.text1}
                  <br />
                  {card.text2}
                  <br />
                  {card.text3}
                </Typography>
                <Typography
                  mt={2}
                  sx={{ fontSize: 14, lineHeight: "1.2" }}
                >
                  {card.text4}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
        <Box display={{ xs: 'block', md: 'none' }} mt={3}>
          <AutoPlaySwipeableViews
            enableMouseEvents
            slideStyle={styles.slideContainer}
            style={styles.root}
          >
            {cards.map((card) => (
            <Card
              sx={{
                background: "#181E2E",
                borderRadius: "8px",
                minHeight: { xs: 205, sm: 180 }
              }}
              elevation={0}
              key={card.text1}
              className="card"
            >
              <CardContent>
                <img src={card.icon} alt="tinklo" width={46} />
                <Typography
                  sx={{ fontSize: { xs: 16, sm: 20, md: 20 }, fontFamily: "Bold", lineHeight: "1" }}
                  mt={1}
                >
                  {card.text1}
                  {' '}
                  {card.text2}
                  {' '}
                  {card.text3}
                </Typography>
                <Typography
                  mt={2}
                  sx={{ fontSize: 14, lineHeight: "1.2" }}
                  gutterBottom
                >
                  {card.text4}
                </Typography>
              </CardContent>
            </Card>
          ))}
          </AutoPlaySwipeableViews>
        </Box>
      </Container>
    </Box>
  );
}
